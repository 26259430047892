import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import PublicCasestudyList from "src/component/list/publicCasestudyList"

export default function Public() {
    return (
        <>
         <Industries>
          <div className="public inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_public.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_public-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>公共/運輸</p>
                                  <span>PUBLIC/TRANSPORTATION/MOBILITY</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  生活インフラを支える電気・ガス・水道・交通などの業界の課題を解決。
                                  <br />
                                  設備稼働データや利用状況データを活用し、効率的な運用管理とサービス向上を実現します。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIが導く、<br />持続可能で効率的な<br className="c-sp" />インフラの未来</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>交通データ基盤の構築</h3>
                                 <p>
                                   運行データを統合し、利用状況を可視化して最適な運行計画を策定。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>エネルギー最適化の導入</h3>
                                 <p>
                                    AIが電力消費を最適化し、エネルギーコストを削減。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIによる需要予測と運行計画最適化</h3>
                                 <p>
                                  AIによる需要予測と運行計画最適化
                                  <br />
                                  利用者数を予測し、最適な運行スケジュールを生成
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>スマートシティ戦略の推進</h3>
                                 <p>
                                    都市全体のデータを統合し、持続可能なインフラ運用を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>設備メンテナンスの高度化</h3>
                                 <p>
                                     センサーデータを分析し、インフラ設備の予兆保全を強化。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <PublicCasestudyList />
                    </div>
                </section>
              </div>
            </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="PUBLIC"
            description=""
            pathname=""
        />
    )
}